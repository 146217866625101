<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      :rules="formRules.email"
    >
      <v-text-field
        v-model="form.email"
        clearable
        flat
        label="Correo electrónico"
        :error-messages="errors"
      />
    </ValidationProvider>
    <v-text-field
      v-model="form.telefono"
      clearable
      flat
      label="Teléfono"
    />
    <v-text-field
      v-model="form.nombre"
      clearable
      flat
      label="Nombre"
    />
    <v-text-field
      v-model="form.nif"
      clearable
      flat
      label="NIF"
    />
    <ValidationProvider
      v-slot="{ errors }"
      :rules="formRules.clasificacion"
    >
      <b10-autocomplete
        v-model="form.clasificacion"
        :items="clasificacionDestinatario"
        item-value="id"
        item-text="descripcion"
        label="Tipo de destinatario"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-subheader
      label="Clasificación"
      :icon="$vuetify.icons.values.send"
    />
    <v-checkbox
      v-model="form.notifica_sat"
      label="Servicio técnico"
    />
    <v-checkbox
      v-model="form.notifica_comercial"
      label="Comercial"
    />
    <v-checkbox
      v-model="form.notifica_admon"
      label="Administración"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './SobreDestinatarioFormData'
import { SOBRE_DESTINATARIO } from '@/utils/consts'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        email: '',
        telefono: '',
        nombre: '',
        nif: '',
        clasificacion: [],
        body: null,
        notifica_sat: false,
        notifica_comercial: false,
        notifica_admon: false,
      },
      formRules: {
        email: { required: true, email: true },
        clasificacion: { required: true },
        asunto: { required: true },
      },
      clasificacionDestinatario: [],
    }
  },
  async created () {
    this.clasificacionDestinatario = _.map(SOBRE_DESTINATARIO.clasificacion, (id) => ({
      id,
      descripcion: SOBRE_DESTINATARIO.descripcion[id],
    }));
    // Poner el destinatario normal por defecto
    if (this.isInsert) {
      this.$set(this.form, 'clasificacion', SOBRE_DESTINATARIO.clasificacion.normal)
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.form = formData
    },
  },
}
</script>
